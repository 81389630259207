import React, { useEffect } from "react";

const Chatbot = () => {
  useEffect(() => {
    // Create the script element
    const script = document.createElement("script");
    script.src = "https://ashnoo.ai/api/alfaromeowidget.js";
    script.type = "text/javascript";
    script.async = true;

    // Append the script to the body
    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="chatbot-container"></div>;
};

export default Chatbot;
